:root {


  --brandColor: #3498DB;

}

.ant-avatar{

  line-height: 40px !important;
  margin:0;
}

.close:hover{

  color:red;
  background-color: #f5f5f5;

}

.close{

  display:none;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color:red;
  font-size:3.5rem !important;

}


.imgcontainer{
  position:relative;
  background-color:#f5f5f5;
  
  
    
  }

  .imgcontainer:hover .close{

    font-size:1rem;
    display:block;
  

  }

  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.513); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

