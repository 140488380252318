@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,800&display=swap');

:root {
  --brandColor:  #69687c;
  --acentColor:#161F60;
  --acentColor2: #605e75;
  --textColor:#706F6F;
  --navTextColor:#000000;
  --bgColor:#e6007f21;

  --headingFont:'Montserrat', sans-serif;
    --textFont:'Poppins', sans-serif;

}

body {
  margin: 0;
  font-family:'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}
@import '~antd/dist/antd.css';

.ant-avatar {
  margin-top: 16px;
  margin-right: 16px;
}
.ant-row-rtl .ant-avatar {
  margin-right: 0;
  margin-left: 16px;
}


*{

  margin:0;
  padding: 0;
}


.ant-layout-content {


  margin:0 !important;
}

.ant-layout-sider-children{

box-shadow: 0px 15px 10px 4px #6d6d6d2e !important;

}


.ant-btn-primary  {

  font-size: 1rem !important;
  height: auto !important;
  letter-spacing: 0.3px;
  font-family:'Poppins', sans-serif!important;
}

.ant-btn-primary  svg {
  font-size: 1.1rem !important;
font-weight: 600;
font-family:'Poppins', sans-serif;

transform: translateY(-4px);

}
.ant-btn-primary span {

  font-family:'Poppins', sans-serif;

}

.ant-form-item-label > label {
  font-family:'Poppins', sans-serif!important;

  letter-spacing: 0.2px !important;
  color: #444444e8 !important;
  font-size: 16px !important;
  text-transform: capitalize!important;
}


.ant-modal-title {
  color: rgb(52 152 219) !important;
  font-size: 21px !important;
  letter-spacing: 0.8px!important;
  font-family:'Poppins', sans-serif!important;

}


.ant-input {
  font-family:'Poppins', sans-serif!important;

  color: rgba(0, 0, 0, 0.85);
  font-size: 18px !important;
  background-color: #f4f9ff !important;
  border: none !important;
  border-radius: 2px !important;
  border-radius: 4px !important;
}

.ant-input-affix-wrapper {

  background-color: #f4f9ff !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 18px !important;
  font-family:'Poppins', sans-serif!important;
  box-shadow: 1px 1px 3px 0px #dedede;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {

  font-size: 20px!important;
  background-color: #f4f9ff!important;
  font-family:'Poppins', sans-serif!important;

}



thead[class*="ant-table-thead"] th{
  background-color: #4d5f71 !important;
  color:rgb(250, 250, 250) !important;
  font-size: 1.1rem;
  font-weight: 400 !important;

}

.ant-btn-ghost {
  color: rgb(255 255 255) !important;
  background: #1890ff !important;
  border-color: #1890ff !important;
}

.ant-btn-ghost svg {

  transform: translateY(-3px);
}
.ant-table table {

  border-spacing: 0 0.3rem !important;
}

.ant-table table > tr {
padding: 0 !important;
font-family:'Poppins', sans-serif!important;

}

.ant-table-thead > tr > th {

padding: 0.4rem 0.6rem !important;
font-family:'Poppins', sans-serif!important;


}

.ant-table-tbody > tr > td {
  font-family:'Poppins', sans-serif!important;

  padding: 0.4rem 0.6rem !important;
  background-color: #F1F6F9 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.ant-table-row-expand-icon {

  background: #f1f6f9 !important;
  border: 1px solid #f1f6f9 !important;
  transform: scale(1.34117647) !important;
  transform-origin: center !important;


}
.ant-table-row-expand-icon:focus {

  outline: none !important;

}
.ant-table-row-expand-icon::before, 
.ant-table-row-expand-icon::after

{

  background-color: var(--brandColor) !important;
}

.ant-input-number {

  font-family:'Poppins', sans-serif!important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: 1px 1px 3px 0px #dedede;
  padding: 0.2rem !important; 
  background-color: #f4f9ff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  width:100% !important;

}

textarea.ant-input {

    box-shadow: 1px 1px 3px 0px #dedede;


}

.ant-input-group.ant-input-group-compact .ant-input {

      box-shadow: 1px 1px 3px 0px #dedede;

}
.ant-table-cell p{

  margin:0;
}

 .ant-select-selector {

  font-family:'Poppins', sans-serif!important;
  border: none !important;
  border-radius: 4px !important;
  padding: 0.2rem 0.5rem!important;
  background-color: #f4f9ff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  width:100% !important;
  box-shadow: 1px 1px 3px 0px #dedede;

}

.ant-table-filter-trigger {
  color: #ffffff !important;
}

.ant-select-selector{
  
  border-radius: 4px !important;

}